<template>
  <div class="overflow-hidden"  >
    <a-spin :spinning="spinnings" tip="資料載中...">
    <div class="d-flex flex-column min-vh-100">
    <b-alert
        v-model="visible"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000;"
        variant="danger"
        dismissible
        >          
      <span v-html="answer" />
    </b-alert> 
    <a-drawer
      placement="right"
      :closable="false"
      :visible="display"
      @close="onClose"
    >
      <a-card :title="cardtitle" :bordered="false">
          <a-button slot="extra" type="primary" @click="onClose" shape="round">關閉</a-button>
          <div v-html="cardcontent"></div> 
      </a-card>

    </a-drawer>            
      <div class="lineHeader">
        <a-button type="link" @click="toNext()" icon="left" class="fontstyle">
          回上一頁
        </a-button>        
      </div>      
        <ul>
          <li>
            <div class="mt-3" id="itemlist" >   
                <div style="padding:1.7rem 0 0 0;" />  
                    <a-calendar @select="onSelect" @panelChange="onChangeDate" :fullscreen="true" :defaultValue="currentTime" :locale="locale"  :style="{ height:'49.8rem' ,border: '1px solid #d9d9d9', borderRadius: '4px' }" class="overflow-hidden" >
                        <div slot="dateCellRender" slot-scope="value" class="events" style="overflow-x: hidden;overflow-y:hidden;">
                          <div v-for="item  in getListData(value)" :key="item.content"  >
                            <div class="notes" :style="{'background-image': item.type==='Y'?'linear-gradient(to bottom, #c5ddf8, #3386e7)':'linear-gradient(to bottom, #dbdedb, #aeb1ae)','cursor':'pointer'}">   
                              <b-icon :icon=" item.type==='Y'? 'flag-fill':'file-earmark-excel-fill'" style="margin-top:1.8rem;color:aliceblue;" font-scale="2" ></b-icon>                           
                            </div>
                          </div>
                        </div>  
                        <template slot="monthCellRender" slot-scope="value">
                            <div v-if="getMonthData(value)" class="notes-month">
                            <section>{{ getMonthData(value) }}</section>
                            <span>Backlog number</span>
                            </div>
                        </template>
                    </a-calendar> 
            </div>
          </li>
        </ul> 
        <div style="padding:3rem 0 0 0;" />
      <div class="applyfooter">
        <Acbottommenu @scanCode="scanCode" @toLocal="toLocal" ref="con3"  />
      </div>              
    </div>
      <b-modal ref="my-act" size="sm" centered @hide="onHide" id="modal-4" :title="mytitle" hide-footer>
        <div class="text-center">
                <div v-if="!list" @click="toclose()" style="cursor: pointer">                
                <div class="qrheader">
                  <span v-text="'關閉QRCoder 請直接點選圖片'"  />
                </div>
                <div class="qrbody">
                  <vue-qr
                      ref="qrCode"
                      :text="textValue"
                      :logoSrc="logoPath"
                      :logoScale="40" 
                      :size="350" 
                      :margin="10"
                  />
                </div> 
                </div>
                <div v-if="list">
                    <div
                      v-for="(item, $index) in information"
                      :key="$index"  
                      style="margin:5px 5px 15px 5px;border-top-right-radius:25px; 
                      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;"      
                    >
                      <b-card
                        :img-src="item.url"
                        img-alt="Image"
                        img-top
                        class="mb-2"
                      >
                        <b-card-text>
                            <div class="text-left fontstyle2">
                              <div v-text="rename(item.actitle)" class="fontstyle2" style="padding-left: 1rem;color:black;" />
                            <div v-text="'時間 : '+ converts(item.sdate,item.edate)" style="padding-left: 0.5rem;  font-size: 0.8rem;font-weight:bold;color: #383030;" /> 
                            <b-button-group size="lg" >
                                  <b-button class="fontstyle" style="width:82px;margin:0px;"  variant="success" @click="toConten(item.fid)"><b-icon icon="calendar2-week" aria-hidden="true"></b-icon></b-button>                            
                                  <b-button class="fontstyle" style="width:82px;margin:0px;"  variant="dark" :disabled="true"><span v-text="item.states=='Y'?'已報到':'未報到'" /></b-button>
                                  <b-button class="fontstyle" style="width:82px;margin:0px;"  variant="danger" @click="delItem(item,item.roles)" v-if="item.states!=='Y' && item.sdate >fdateformat(currentTime)"><b-icon icon="trash" aria-hidden="true"></b-icon></b-button>
                            </b-button-group>  
                        </div>  
                        </b-card-text>
                      </b-card>
                    </div>  
                </div>                
        </div>
      </b-modal>    
    </a-spin>        
  </div>
</template>

<script>
import { joinService,actService } from '../_services'
import { mapState,mapMutations } from "vuex";
import * as moment from "moment/moment";
import MY_JSON from '../assets/json/optiondata.json'
import VueQr from 'vue-qr'
import router from '../router'
import 'moment/locale/zh-tw';
import logoImg from '@/assets/lineicon.svg'
import Acbottommenu from '../myline/panel/Acbottommenu.vue'
import { mixinWebsocket } from '../wsocket/wsobj.js';
import store from "../store/index"


const deleteItemam = params => {
  return actService.delMan(params.id, params.aid, params.fid, params.uid, params.roles);
};

const findall = obj => {
  return joinService.singmydate(obj) 
};


export default {
  name: 'Mydoing',
  mixins: [mixinWebsocket],  
  components: {
    VueQr,
    Acbottommenu
  },
  props: {
    msg: String
  },
  data() {
    return {
      myJson: MY_JSON,   
      spinnings: false,     
      showTop: false,                 
      message:'',
      currentTime:moment(),
      myDates:moment(),
      mytitle:'',
      returndata:[],
      information:[],
      logoPath:logoImg,
      textValue:'',
      list:true,
      visible:false,
      display:false,
      answer:'',  
      cardtitle:'', 
      cardcontent:'',
      locale:{
        lang:{
          month:'月',
          year:'年',
        }
      },          
    };
  },  
  async mounted(){
    this.myDates = this.getdate(this.myDates)
    if(this.user!==null){
        let obj= {id:'Myline'}
        await this.setorgpage(obj).then((val)=>{return val}).catch((val)=>{return val}).finally(() => {console.log('set page done')})   
        let names = store.state.account.targetpage
        if(names=='Myline'){  
          this.getLinecheck()
          if(this.linecheck==true){
            this.getfetchall({id:this.user.data.user.id,myDate:this.myDates})
            this.initWebsocket();  
          }        
        }      
    }

  },
  computed: {
    ...mapState("account", ["user", "user","linecheck"]),
    ...mapState("ws", ["wsNotify","wsState","socket"]),  
  }, 
  watch: {    
    wsNotify(val){
        if(val){
          let {data} =val; 
          if(data!==null){
            if(data.type==='order'){                
              this.cardtitle=data.title
              this.cardcontent=data.rmg
              if(data.title.replace("活動名稱 :","").trim()==!''){
                this.display = true;  
              }else if(data.title.replace("活動名稱 :","").trim()==''){
                this.visible=true;
                this.answer = '重複報到 ' + '<br/>'
                setTimeout(() => {
                  this.answer=''
                  this.visible=false;  
                }, 1400);                
              }
              this.getfetchall({id:this.user.data.user.id,myDate:this.myDates})       
            }
          }                     
        }
    },

    socket(val){
      if(val!==null){
        if(val.url==undefined){ 
          this.initWebsocket();
        }
      }
    }    

  },      
  methods: {   
    ...mapMutations('account',['setpage','getLinecheck']),       
    query(rs){
      this.classtheme = rs
    }, 
    fdateformates(val) {
      return (
        moment(val.sdate).format("YYYY-MM-DD HH:mm") +
        "~" +
        moment(val.edate).format("HH:mm")
      );
    },    
    getfetchall(params = {}) {
      this.spinnings=true
      findall({
        ...params,
      }).then(({ data }) => { 
          this.spinnings=false         
          this.returndata = data;
      });
    }, 
    delItem(items,roles) {
      const self = this;
      this.$confirm({
        title:
          "您確定要刪除該課程?",
        content: "活動名稱 : " + items.actitle + '|時間:'+items.sdate,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        zIndex: 9999,
        async onOk() {
          await self.removem(items,roles);
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    },  

    rename(value){
      return value.length >= 15 ? value.substring(0,10)+'...':value
    },  
    
    converts(v1,v2){
      return moment(v1).format("YYYY-MM-DD HH:mm") +'~'+ moment(v2).format("HH:mm");
    },    
    
    async removem(items,roles) {
      await deleteItemam({ id: items.cid, aid: items.aid,fid:items.fid,uid: items.uid,roles }).then(
        ({ data }) => {
          data.code == 200
            ? this.getfetchall({id:this.user.data.user.id,myDate:this.myDates})
            : this.$message.warning("刪除發生錯誤");
            this.$refs["my-act"].hide();
        }
      );
    },    
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    getdate(val){
        return moment(val).format("YYYY-MM-DD"); 
    },    
    toNext(){
      router.replace({ name: 'Myline'})
      //router.go(-1)
    },

    getinfo(theme){
        let url = this.myJson.find(rs=>rs.value==theme).title
        return url
    },
    
    toQrcode(val){
      this.list=false
      let tmp = val.id+","+val.sdate+" "+val.stime+","+val.edate+" "+val.etime+","+val.aid+","+val.fid+","+val.uid
      this.textValue = tmp
    },

    setorgpage(obj){
          return new Promise((resolve)=>{  
            resolve(this.setpage(obj))            
          })          
    },    

    toclose(){
      this.list=true
    },
    
    getListData(value) {
      let listData=[];
      if(this.returndata!==null){
      this.returndata.forEach((item)=>{          
          if(this.getdate(item.sdate) == value.format("YYYY-MM-DD")){
              listData.push(
                  {
                    type: this.getdate(item.sdate) >=this.currentTime.format("YYYY-MM-DD")?'Y':'N',
                    id:item.id,
                    aid:item.aid,
                    fid:item.fid,   
                    uid:item.uid,         
                    orgid:item.orgid,       
                    url:item.url,           
                    actitle:item.actitle,          
                    sdate:item.sdate,         
                    edate:item.edate,
                    stime: item.stime,
                    etime: item.etime,                     
                    state:item.states                                                                                           
                }
              )
          }            
          });
          return listData || [];
      }
      return listData || [];

    },

    toConten(id){
      router.push({ name: 'Myapply', params: { id }})
    },    

    onSelect(e){
      this.mytitle='我的活動資訊'+ moment(e).format("YYYY-MM-DD")
      this.information = this.returndata.filter(rs=>this.getdate(rs.sdate)==moment(e).format("YYYY-MM-DD"))
      if(this.information.length>0){
          this.list=true
          this.$refs["my-act"].show(); 
      }else{
        this.visible=true;
        this.answer = "無資料!"
        setTimeout(() => {
          this.answer=''
          this.visible=false;    
        }, 1400);                  
      }


    },

    onChangeDate(e){
      this.myDates = this.getdate(e._d)
      this.getfetchall({id:this.user.data.user.id,myDate:this.myDates})  
    },

    getMonthData(value) {
      if (value.month() === 8) {
        return 1394;
      }
    }, 

    scanCode(val){
        let value = '報到失敗 :' + '<br/>'
        if(val.code=="200"){
          value =  '報到成功 :' + '<br/>' 
          this.getfetchall({id:this.user.data.user.id,myDate:this.myDates})
        }else if(val.code=="208"){
           value = '報到失敗(活動已額滿) ' + '<br/>'
        }else if(val.code=="209"){
           value = '報名成功 ' + '<br/>' 
           this.getfetchall({id:this.user.data.user.id,myDate:this.myDates})          
        }else if(val.code=="300"){
           value = '重複報到 ' + '<br/>'
        }else if(val.code=="301"){
           value = '重複報名 ' + '<br/>'           
        }else if(val.code=="303"){
           value = '代碼錯誤 ' + '<br/>'           
        }else if(val.code=="400"){
           value = '請確認活動日期 ' + '<br/>'
        }else if(val.code=="401"){
           value = '活動尚未開始, 請確認日期 ' + '<br/>'
        }else if(val.code=="402"){
           value = '活動已經結束, 謝謝  ' + '<br/>'
        }                       
        this.visible=true;
        this.answer = value
        setTimeout(() => {
          this.answer=''
          this.visible=false;  
        }, 1400);          

    },    
    
    scanCode1(val){ //沒用到的Function
        let value = this.validation(val)
        this.visible=true;
        this.answer = value        
        setTimeout(() => {
          this.answer=''
          this.visible=false; 
        }, 1400);        

    },  
    
    validation(val){  //沒用到的Function
      let tmp = '資料不符 : <br/>' + val
      if(val!==""){
        if(val.substring(0, 7)==='actjoin'){          
          let strAry = val.split(',');
          if(strAry.length==6){
            let obj ={
            id: strAry[0],
            sdate: strAry[1],
            edate: strAry[2],
            aid: strAry[3],
            fid: strAry[4],
            uid: strAry[5],
          }
          tmp = '資料正確 : <br/>' + obj
          }
        }
      }
      return tmp
    },
    
    onHide(evt) {
          if(evt.trigger === "backdrop"){
              evt.preventDefault();
          }
      },
      
    onClose() {
      this.display = false;
    }, 
    
    toLocal(val){
      //console.log('latitude:', val);
      this.cardtitle="我的位置"
      this.cardcontent=val
      this.display = true;         
    }
  },    
}
</script>
<style scoped>
#itemlist {
    margin:0px auto;
    padding:0px;
    max-width: 1024px;  
    text-align:left;
 }

  img {
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
    display: block;
    clip-path: polygon(-4px -4px, 100.4% -3px, 99.2% 102.57%, -0.8% 102.97%);
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    width: min(98%, 500px);
    margin: 0 auto;
    box-shadow: 0 20px 34px hsla(20deg, 1%, 52%, 0.29);
    overflow: hidden;
  }

  .cards-details {
    text-align: left;
    position: relative;
    padding: 0 0.05rem 0 0.5rem;
  } 
  
.applyfooter {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 60px;
   background-color: #ffffff;
   color: #f0f8f8;
   text-align: center;
   z-index: 7;
   
}  


ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.lineHeader {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 7;
   opacity:1;
   width: 100%;
   height: 2.3rem;
   padding: 0.2rem;
   background-color: #3b1b75;
   color: #f0f8f8;
   text-align: center;
   
}

.fontstyle{
   font-size:0.8rem;
   color:#faf8f8;
}

.fontstyle2 {
font-size: 0.93rem;
font-weight:bold;
color: #2c055e;
padding: 0.4rem;
background:linear-gradient(45deg,#7fdac1 0%,#5decde 60%,#ddf0e6 60%,#93cea2 100%);
opacity:0.85;
}

.notes{
  position: absolute;
  border-radius: 15px;
  top: 25%;
  left: 0;
  width: 80%;
  height: 70%;
  text-align: center; /* 文本水平居中 */
  vertical-align: middle; /* 文本垂直居中 */  
  cursor:pointer;
  }

.qrbody{
    padding:0.4rem;
    background-color: #76d27a;
    color:white;
    border-radius:0 0 0.5rem 0.5rem;
    border-bottom-style: dotted;
    border-width:0.35rem;
    border-left-style: dotted;
    border-right-style: dotted; 
}

.qrheader{
  padding:0.5rem;
  background-color: #6dc871;
  color:white;
  border-radius:1.3rem 1.3rem 0 0;
  border-bottom-style: dotted;
  border-width:0.20rem;
}
.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 30px;
}

.card-body{
  padding:0px;
}

.card-img-top{
  border-top-right-radius:25px;
}
.card{
  border-top-right-radius:25px;
}

</style>
<style >
  .ant-fullcalendar-content{
  overflow-y: hidden;
  overflow-x: hidden;
  }
  .ant-fullcalendar-header .ant-radio-group {
    display: none !important;
  }
</style>

