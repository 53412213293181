<template>
  <div>
    <div style="width:100%;max-height:60px;">
      <div class="leftbtn" @click="toQr()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-qr-code"
          viewBox="0 0 16 16"
        >
          <path d="M2 2h2v2H2V2Z" />
          <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
          <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
          <path
            d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"
          />
          <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
        </svg>
        掃描QRCode
      </div>
      <div class="rightbtn" @click="myCode()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-qr-code"
          viewBox="0 0 16 16"
        >
          <path d="M2 2h2v2H2V2Z" />
          <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
          <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
          <path
            d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"
          />
          <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
        </svg>
        我的QRCode
      </div>
      <!--<div class="rightbtn" @click="locateMe()" ><b-icon icon="cursor-fill" aria-hidden="true"></b-icon> 我的位置</div>-->
    </div>
    <div v-show="false">
      <GmapMap
        :center="{lat:0, lng:0}"
        :zoom="17"
        map-type-id="terrain"
        style="width: 0px; height: 0px"
      ></GmapMap>
    </div>

    <b-modal
      ref="my-qr"
      size="sm"
      centered
      @hide="onHide"
      id="modalQr"
      title="我的QR Code"
      hide-header
      hide-footer
    >
      <div class="text-center" id="cheader">
        <b-button @click="closecam()" style="color:#000204;" variant="link">
          <b-icon icon="camera" font-scale="1.5" variant="dark"></b-icon>關閉
        </b-button>
      </div>
      <div class="text-center" id="cbodyer">
        <qrcode-stream
          :key="qrcodeKey"
          :camera="camera"
          :track="paintOutline"
          @init="onInit"
          v-if="!destroyed"
          @decode="onDecode"
        >
        </qrcode-stream>
      </div>
    </b-modal>

    <b-modal ref="my-qrs" centered @hide="onHide" id="modalQrXy" title hide-header hide-footer>
      <div class="text-center" id="cbodyer">
        <div v-if="!list" @click="toclose()" style="cursor: pointer;">
          <div class="qrheader" style="color: black;">
            <span v-text="'關閉QRCoder 請直接點選圖片'" style="margin-right: 1rem;"/>
            <a-button
              type="link"
              icon="printer"
              style="margin-left: 1rem;font-size: 16px;color:black;"
              :loading="iconLoading2"
              @click="exportToPDF"
            >列印</a-button>
          </div>
          <div class="qrbody">
            <vue-qr
              ref="qrCode"
              :text="textValue"
              :logoSrc="logoPath"
              :logoScale="40"
              :margin="10"
            />
            <div class="classempy" id="word1head" v-show="iconLoading2">
              <div id="word123" class="text-center">
                <span style="font-size: 28px;margin-bottom: 2rem;" v-text="displayname"></span>
                <vue-qr
                  ref="qrCode"
                  :text="textValue"
                  :logoSrc="logoPath"
                  :logoScale="40"
                  :size="650"
                  :margin="10"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
//import router from '../../router'
import { mapState, mapMutations } from "vuex";
//import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import * as moment from "moment/moment";
import { QrcodeStream } from "vue-qrcode-reader";
import { joinService, macService } from "../../_services";
import { gmapApi } from "vue2-google-maps";
import VueQr from "vue-qr";
import logoImg from "@/assets/lineicon.svg";
import html2pdf from "html2pdf.js";
//import VueCookies from 'vue-cookies'

const postData = params => {
  return joinService.signin(params);
};

const postApply = params => {
  return joinService.postApply(params);
};

const queryus = id => {
  return macService.macfind(id);
};

export default {
  name: "Acbottommenu",
  components: {
    QrcodeStream,
    VueQr
  },
  props: {
    obj: Object
  },
  data() {
    return {
      xurl: "",
      imgs: {
        width: 0,
        height: 0
      },
      qrcodeKey: 0,
      loading: false,
      destroyed: false,
      camera: "auto",
      list: true,
      showScanConfirmation: false,
      gettingLocation: false,
      logoPath: logoImg,
      textValue: "",
      errorStr: null,
      currentTime: moment(),
      displayname: "",      
      iconLoading2: false,      
    };
  },
  computed: {
    ...mapState("account", ["user"]),
    ...mapState("sing", ["singrecords"]),
    google: gmapApi
  },
  mounted() {
    this.singini();
  },

  methods: {
    ...mapMutations("sing", ["singini", "singadd", "singremov"]),
    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },

    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.showScanConfirmation = this.camera === "off";
      }
    },

    myCode() {
      this.list = false;
      this.textValue = "actjoin:" + this.user.data.user.id;
      this.displayname = this.user.data.user.display_name;
      this.$refs["my-qrs"].show();
    },

    toclose() {
      this.list = true;
      this.$refs["my-qrs"].hide();
    },

    async reload() {
      this.destroyed = true;

      await this.$nextTick();

      this.destroyed = false;
    },

    covert(type, val) {
      if (type == "theme") {
        let rs = this.mytheme.filter(el => {
          return el.value === val;
        });
        return rs[0].title;
      }
    },

    toGo() {
      //this.$emit("savelove","love")
      //this.user.data.user.id
    },

    toGoact() {
      //this.$emit("savelove","act")
    },

    toQr() {
      this.qrcodeKey++;
      this.unpause();
      this.reload();
      this.$refs["my-qr"].show();
    },
    closecam() {
      this.$refs["my-qr"].hide();
    },

    async onDecode(decodedString) {
      this.pause();
      let rs = this.validation(decodedString); //驗證報到時間
      if (rs !== ""  && rs !== "finish" && rs !== "apply") {
        let ch1 = this.singrecords.find(element => element == rs.fid);
        //console.log('1.',ch1,rs.fid)
        if (this.singrecords.length == 0 || ch1 == undefined) {
          let tman = await this.getUs(rs)
            .then(val => {
              return val;
            })
            .catch(val => {
              return val;
            });
          if (tman === "data") {
            await this.toScan(rs) //報到一
              .then(val => {
                return val;
              })
              .catch(val => {
                return val;
              })
              .finally(() => {
                console.log("done");
              });
            this.singadd(rs.fid);
          } else {
            this.$emit("scanCode", { code: "303" });
          }
        } else {
          this.$emit("scanCode", { code: "300" });
        }
      } else if(rs === "apply"){
        decodedString = decodedString.replace("actjoin:","")           
        let strAry = decodedString.split(",");        
        let rs = {
              id: parseInt(strAry[3]),
              sdate: strAry[1],
              edate: strAry[2],
              fid: parseInt(strAry[0]),
              uid:this.user.data.user.id,
              sid: parseInt(strAry[4]),
              limits: parseInt(strAry[5])
            };
        await this.toApply(rs) //報到二
            .then(val => {
              return val;
            })
            .catch(val => {
              return val;
            });
      } else {
        let code = "400";
        rs == "notYet" ? (code = "401") : (code = "400");
        rs == "finish" ? (code = "402") : (code = "400");
        this.$emit("scanCode", { code: code });
      }

      await this.timeout(1400);
      //this.unpause();
      this.closeCal()
    },

    closeCal() {
      this.destroyed = true;
      this.$refs["my-qr"].hide();
    },    

    unpause() {
      this.camera = "auto";
    },

    pause() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms);
      });
    },

    toScan(val) { //報到
      return new Promise((resolve, reject) => {
        let now = moment();
        let tmp = { msg: "done", code: 300, data: {} };

        var dateTimeString = val.sdate; //提早一小時
        var parsedMoment = moment(dateTimeString, "YYYY-MM-DD HH:mm");
        var oneHourBefore = parsedMoment.subtract(1, 'hours');
        var myDates = oneHourBefore.format('YYYY-MM-DD HH:mm');

        var dateTimeString2 = val.edate; //晚一小時
        var parsedMoment2 = moment(dateTimeString2, "YYYY-MM-DD HH:mm");
        var oneHourAfter = parsedMoment2.add(1, 'hours');
        var myDates2 = oneHourAfter.format('YYYY-MM-DD HH:mm');

        if (
          myDates <=
          now.format("YYYY-MM-DD HH:mm") &&
          myDates2 >
            now.format("YYYY-MM-DD HH:mm")
        ) {
          //let tmp = {check:true, code:200,data:[]}
          val.sdate = moment(val.sdate).format("YYYY-MM-DD HH:mm");
          val.edate = moment(val.edate).format("YYYY-MM-DD HH:mm");
          postData({
            ...val
          }).then(({ data }) => {
            this.$emit("scanCode", {code:data.data.code});
            if (data.data.code == 200) {
              //this.$emit("scanCode", data);
              resolve(data);
            } else {
              reject(tmp);
            }
          });
        } else {
          reject(tmp);
        }
      });
    },

    validation(val) {
      let tmp = "";
      if (val !== "") {
        if (val.substring(0, 7) === "actjoin") {
          val = val.replace("actjoin:","")           
          let strAry = val.split(",");
          if (strAry.length == 6) {
            let obj = {
              id: parseInt(strAry[3]),
              sdate: strAry[1],
              edate: strAry[2],
              fid: parseInt(strAry[0]),
              uid: this.user.data.user.id,
              sid: parseInt(strAry[4]),
              limits: parseInt(strAry[5])
            };
            tmp = obj;  
            // 提早一小時
            var dateTimeString = strAry[1];
            var parsedMoment = moment(dateTimeString, "YYYY-MM-DD HH:mm");
            var oneHourBefore = parsedMoment.subtract(1, 'hours');
            var myDates = oneHourBefore.format('YYYY-MM-DD HH:mm');
            // 晚一小時
            var dateTimeString2 = strAry[2];
            var parsedMoment2 = moment(dateTimeString2, "YYYY-MM-DD HH:mm");
            var oneHourAfter = parsedMoment2.add(1, 'hours');
            var myDates2 = oneHourAfter.format('YYYY-MM-DD HH:mm');
            if (
              !( //目前到時間大於提早一小時 and 結束時間大於目前的時間
                myDates <
                  this.fdateformat(this.currentTime) &&
                  myDates2 > this.fdateformat(this.currentTime)
              )
            ) {
              tmp = "";
            }
            if (  // 活動開始時間 > 現在時間 = 活動還沒開始
              myDates > this.fdateformat(this.currentTime)
            ) {
              tmp = "apply";
            }
            if ( //目前的時間大於結束時間
              myDates2 < this.fdateformat(this.currentTime)
            ) {
              tmp = "finish";
            }  
            //console.log(tmp,myDates,this.fdateformat(this.currentTime))        
          }
        }
      }
      return tmp;
    },

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD HH:mm");
    },

    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";
        ctx.lineWidth = 4;

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },

    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Geolocation is not available."));
        }
        navigator.geolocation.getCurrentPosition(
          pos => {
            resolve(pos);
          },
          err => {
            reject(err);
          }
        );
      });
    },
    async locateMe() {
      this.gettingLocation = true;
      try {
        this.gettingLocation = false;
        let locals = await this.getLocation();
        let val = await this.getgeocode(locals);
        this.$emit("toLocal", val);
      } catch (e) {
        this.gettingLocation = false;
        this.errorStr = e.message;
      }
    },

    //console.log('latitude:', val.coords.latitude,"longitude:",val.coords.longitude);
    async getgeocode(locals) {
      //console.log("latitude:",locals.coords.latitude,"longitude:",locals.coords.longitude)
      return new Promise(resolve => {
        var geocoder = new this.google.maps.Geocoder();
        geocoder.geocode(
          {
            location: {
              lat: locals.coords.latitude,
              lng: locals.coords.longitude
            }
          },
          (results, status) => {
            if (status === "OK") {
              //console.log("formatted_address:",results[0])
              resolve(results[0].formatted_address);
            }
          }
        );
      });
    },
    getUs(val) {
      return new Promise((resolve, reject) => {
        let now = moment();       
        let tmp = null;
        var dateTimeString = val.sdate;
        var parsedMoment = moment(dateTimeString, "YYYY-MM-DD HH:mm");
        var oneHourBefore = parsedMoment.subtract(1, 'hours');
        var myDates = oneHourBefore.format('YYYY-MM-DD HH:mm');

        // 晚一小時
        var dateTimeString2 = val.edate;
        var parsedMoment2 = moment(dateTimeString2, "YYYY-MM-DD HH:mm");
        var oneHourAfter = parsedMoment2.add(1, 'hours');
        var myDates2 = oneHourAfter.format('YYYY-MM-DD HH:mm');        

        if (
          myDates <=
          now.format("YYYY-MM-DD HH:mm") &&
          myDates2 >
            now.format("YYYY-MM-DD HH:mm")
        ) {
          queryus(val.fid).then(({ data }) => {
            if (data.states !== "" && data.states === "Y" ) {
              tmp = "data";
              resolve(tmp);
            } else {
              reject(tmp);
            }
          });
        } else {
          reject(tmp);
        }
      });
    },

    toApply(val){
      return new Promise((resolve, reject) => {
        var currentMoment = moment();
        var dateTimeString = val.sdate;
        var parsedMoment = moment(dateTimeString, "YYYY-MM-DD HH:mm");
        var oneHourBefore = parsedMoment.subtract(1, 'hours');
        var myDates = oneHourBefore.format('YYYY-MM-DD HH:mm');        
        let tmp = null;
        if (
          myDates >
          currentMoment.format("YYYY-MM-DD HH:mm")
        ) {
          postApply(val).then(({ data }) => {
            this.$emit("scanCode", {code:data.data.code});
            if (data.data.code == 209 ) {
              tmp = "data";
              resolve(tmp);
            } else {
              reject(tmp);
            }
          });
        } else {
          reject(tmp);
        }
      });
    },
    exportToPDF() {
      // pdf匯出設定
      this.iconLoading2 = true;
      const opt = {
        margin: 0.3,
        filename: "QRCode.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: { dpi: 192, letterRendering: true, useCORS: true },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" }
      };
      this.domList = [];
      // 抓取各個圖片的DOM元素，並把它裝入doc這個陣列裡
      let newDiv = document.createElement("div");
      //let j = this.items.act_detrimal.length
      let head1 = document.getElementById("word1head");
      const clone1 = head1.cloneNode(true);
      newDiv.appendChild(clone1);
      let element1 = document.getElementById("word123");
      this.domList.push(element1);
      // 開始進行列印
      let doc = html2pdf()
        .from(this.domList[0])
        .set(opt)
        .toPdf();
      for (let j = 1; j < this.domList.length-1; j++) {
        doc = doc
          .get("pdf")
          .then(pdf => pdf.addPage())
          .from(this.domList[j])
          .toContainer()
          .toCanvas()
          .toPdf();
      }
      // 列印完成輸出done
      doc
        .save()
        .then(() => (this.iconLoading2 = false));
      //
    }, 


  }
};
</script>
<style scoped>
.leftbtn {
  background-color: #30c272;
  color: #e9eaeb;
  width: 50%;
  height: 60px;
  float: left;
  display: inline;
  text-align: center;
  line-height: 60px;
}
.leftbtn:hover {
  background-color: #b6c8cc;
  color: #ffffff;
  cursor: pointer;
}
.modbtn {
  background-color: #30c272;
  color: #ffffff;
  width: 25%;
  height: 60px;
  float: left;
  display: inline;
  text-align: center;
  line-height: 60px;
}
.modbtn:hover {
  background-color: #63a380;
  color: #ffffff;
  cursor: pointer;
}

.rightbtn {
  background: linear-gradient(90deg, #65b5f7 0%, #4995d3 35%, #4184d0 100%);
  width: 50%;
  height: 60px;
  float: left;
  display: inline;
  border-width: 1px;
  border-color: #65b5f7;
  border-left-style: dotted;
  text-align: center;
  line-height: 60px;
}

.rightbtn:hover {
  background: linear-gradient(90deg, #7dc3fd 0%, #62b5f8 35%, #4aa0e7 100%);
  cursor: pointer;
}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.imgs {
  margin: 25%;
  width: 60px;
  height: 60px;
}
#cheader {
  margin: 0px auto;
  padding: 0px;
  background-color: #ffffff;
  width: 50%;
  border-top-left-radius: 10.5rem;
  border-top-right-radius: 10.5rem;
}
#cbodyer {
  padding: 10px;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
<style>
#modalQr > .modal-dialog > .modal-content > .modal-body {
  border-color: transparent;
  background-color: transparent !important;
  color: white;
}
#modalQr > .modal-dialog > .modal-content {
  border-color: transparent;
  background-color: transparent !important;
}
</style>

